import React from 'react'
import { Element } from 'react-scroll'

import styles from './Section.module.scss'

export default ({ children, name }) => {
  const [documentation, code] = React.Children.toArray(children)

  return (
    <section>
      <Element className={styles.section} name={name}>
        <div className={styles.documentation}>{documentation}</div>
        {code && <div className={styles.code}>{code}</div>}
      </Element>
    </section>
  )
}
