import React from 'react'
import Link from 'gatsby-link'
import { Link as ScrollLink } from 'react-scroll'
import Hamburger from '../../../shared/Hamburger'
import classNames from 'classnames'

import Logo from '../../../../assets/images/inline/logo.svg'
import styles from './SideNav.module.scss'

const cx = classNames.bind(styles)

export default class SideBar extends React.Component {
  state = {
    isOpen: false,
  }

  render() {
    return (
      <React.Fragment>
        <section
          className={cx(styles.sidebar, { [styles.isOpen]: this.state.isOpen })}
        >
          <header className={styles.header}>
            <Link className={styles.logoLink} to="/" aria-label="Home page">
              <Logo className={styles.logo} />
            </Link>
          </header>

          <nav className={styles.nav}>
            <ul className={styles.list}>
              <li>
                <ScrollLink
                  href="#authentication"
                  className={styles.link}
                  activeClass={styles.activeLink}
                  to="authentication"
                  spy={true}
                  offset={-160}
                  smooth={true}
                  duration={500}
                >
                  Authentication
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  href="#address"
                  className={styles.link}
                  activeClass={styles.activeLink}
                  to="address"
                  spy={true}
                  offset={-160}
                  smooth={true}
                  duration={500}
                >
                  Address Subscription
                </ScrollLink>
                <ul className={styles.list}>
                  <li>
                    <ScrollLink
                      href="#address-create"
                      className={styles.sublink}
                      activeClass={styles.sublinkactive}
                      to="address-create"
                      spy={true}
                      offset={-160}
                      smooth={true}
                      duration={500}
                    >
                      Create
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      href="#address-delete"
                      className={styles.sublink}
                      activeClass={styles.sublinkactive}
                      to="address-delete"
                      spy={true}
                      offset={-160}
                      smooth={true}
                      duration={500}
                    >
                      Delete
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      href="#address-webhook"
                      className={styles.sublink}
                      activeClass={styles.sublinkactive}
                      to="address-webhook"
                      spy={true}
                      offset={-160}
                      smooth={true}
                      duration={500}
                    >
                      Webhook
                    </ScrollLink>
                  </li>
                </ul>
              </li>
              <li>
                <ScrollLink
                  href="#transaction"
                  className={styles.link}
                  activeClass={styles.activeLink}
                  to="transaction"
                  spy={true}
                  offset={-160}
                  smooth={true}
                  duration={500}
                >
                  Transaction Subscription
                  <ul className={styles.list}>
                    <li>
                      <ScrollLink
                        href="#transaction-create"
                        className={styles.sublink}
                        activeClass={styles.sublinkactive}
                        to="transaction-create"
                        spy={true}
                        offset={-160}
                        smooth={true}
                        duration={500}
                      >
                        Create
                      </ScrollLink>
                    </li>
                    <li>
                      <ScrollLink
                        href="#transaction-delete"
                        className={styles.sublink}
                        activeClass={styles.sublinkactive}
                        to="transaction-delete"
                        spy={true}
                        offset={-160}
                        smooth={true}
                        duration={500}
                      >
                        Delete
                      </ScrollLink>
                    </li>
                    <li>
                      <ScrollLink
                        href="#transaction-webhook"
                        className={styles.sublink}
                        activeClass={styles.sublinkactive}
                        to="transaction-webhook"
                        spy={true}
                        offset={-160}
                        smooth={true}
                        duration={500}
                      >
                        Webhook
                      </ScrollLink>
                    </li>
                  </ul>
                </ScrollLink>
              </li>
            </ul>
          </nav>
        </section>
        <Hamburger
          className={styles.toggle}
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        />
      </React.Fragment>
    )
  }
}
