import React from 'react'

import Layout from '../components/layout'
import SideNav from '../components/pages/Docs/SideNav'
import Container from '../components/pages/Docs/Container'

class Docs extends React.Component {
  state = {
    isMobile: false,
  }

  componentDidMount() {
    this.setState({ isMobile: window.matchMedia('(max-width: 960px)').matches })
  }

  render() {
    return (
      <Layout className="Layout--withRelative">
        <SideNav />
        <Container />
      </Layout>
    )
  }
}

export default Docs
