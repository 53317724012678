import React from 'react'
import Prism from 'prismjs'
import { Element, Link as ScrollLink } from 'react-scroll'
import styles from './Container.module.scss'
import Section from '../Section'
import { stripIndent } from 'common-tags'

export default class Container extends React.Component {
  componentDidMount() {
    Prism.highlightAll()
  }

  render() {
    return (
      <main className={styles.container}>
        <Section name="authentication">
          <React.Fragment>
            <h1 className={styles.title}>API Documentation</h1>
            <h2 className={styles.subtitle}>Authentication</h2>
            <p className={styles.description}>
              The Meerkat API uses API keys to authenticate requests. We
              authenticate requests through the Authorization header in your
              HTTP request. All requests must be made over HTTPS or they will
              fail.
            </p>
          </React.Fragment>
          <React.Fragment>
            <p className={styles.codeDescription}>
              Add the following header to your request:
            </p>
            <pre className={styles.pre}>
              <code className={`${styles.code} language-http`}>
                {`Authorization: ApiKey <your api token>`}
              </code>
            </pre>
            <p className={styles.codeDescription}>Example Request:</p>
            <pre className={styles.pre}>
              <code className={`${styles.code} language-javascript`}>
                {stripIndent`
                  fetch(url, {
                    method: "POST", 
                    headers: {
                      "Authorization": "ApiKey <your api token>",
                    }
                  })
                `}
              </code>
            </pre>
          </React.Fragment>
        </Section>
        <section>
          <Element name="address">
            <Section>
              <React.Fragment>
                <h2 className={styles.subtitle}>Address Subscription</h2>
                <p className={styles.description}>
                  To create an address subscription, you need to provide a
                  currency type, callback URL, and address. Whenever an inbound
                  or outbound transaction occurs, the provided callback URL will
                  be called with the transaction details.
                </p>
              </React.Fragment>
            </Section>
            <Section name="address-create">
              <React.Fragment>
                <h3 className={styles.tertiarytitle}>
                  Create Address Subscription
                </h3>
                <div className={styles.attributes}>
                  <h4 className={styles.attributeTitle}>Body Attributes</h4>
                  <ul className={styles.table}>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>currency</h5>
                      <p className={styles.attributeDescription}>
                        The following are accepted currencies: <code>BTC</code>,{' '}
                        <code>LTC</code>, <code>DOGE</code>, <code>ETH</code>,{' '}
                        <code>ERC20ALL</code>,{' '}
                        <code>ERC20:{`<contract address>`}</code>. Please note{' '}
                        <code>ETH</code> is exclusive of ERC-20 addresses.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>address</h5>
                      <p className={styles.attributeDescription}>
                        A checksummed address. Addresses without a valid
                        checksum will result in an error response.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>callback</h5>
                      <p className={styles.attributeDescription}>
                        A valid callback URL that accepts a <code>POST</code>{' '}
                        request. This is the URL that our server will send push
                        notifications to subsequent to address activity.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className={styles.attributes}>
                  <h4 className={styles.attributeTitle}>Response</h4>
                  <ul className={styles.table}>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>subscription_id</h5>
                      <p className={styles.attributeDescription}>
                        A unique ID which can be used to delete the
                        subscription.
                      </p>
                    </li>
                  </ul>
                </div>
              </React.Fragment>
              <React.Fragment>
                <p className={styles.codeDescription}>Example Request:</p>
                <pre className={styles.pre}>
                  <code className={`${styles.code} language-javascript`}>
                    {stripIndent`
                  fetch('meerkat.watch/api/v0/enterprise/subscribe/address', {
                    method: "POST", 
                    headers: {
                      "Content-Type": "application/json",
                      "Authorization": "ApiKey <your api token>"
                    },
                    body: JSON.stringify({
                      "address": "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
                      "currency": "BTC",
                      "callback": "https://example.com/addressActivity/satoshi"
                    })
                  })
                `}
                  </code>
                </pre>
                <p className={styles.codeDescription}>Curl:</p>
                <pre className={styles.pre}>
                  <code className={`${styles.code} language-bash`}>
                    {`curl -XPOST 'https://meerkat.watch/api/v0/enterprise/subscribe/address' \\
  -H 'Authorization: ApiKey <your api token>' \\
  -H "Content-type: application/json" \\
  -d '{"address":"1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa","currency":"BTC","callback":"https://example.com/addressActivity/satoshi"}' 
                    `}
                  </code>
                </pre>
                <p className={styles.codeDescription}>Example Response:</p>
                <pre className={styles.pre}>
                  <code className={`${styles.code} language-javascript`}>
                    {stripIndent`"<subscription id>"`}
                  </code>
                </pre>
              </React.Fragment>
            </Section>
            <Section name="address-delete">
              <React.Fragment>
                <h2 className={styles.tertiarytitle}>
                  Address Delete Subscription
                </h2>
                <p className={styles.description}>
                  Use the <code>subscription_id</code> obtained from the{' '}
                  <ScrollLink className={styles.scrollLink} to="address-create">
                    Create Address Subscription
                  </ScrollLink>{' '}
                  request to delete the subscription.
                </p>
              </React.Fragment>
              <React.Fragment>
                <p className={styles.codeDescription}>Example Request:</p>
                <pre className={styles.pre}>
                  <code className={`${styles.code} language-javascript`}>
                    {stripIndent`
                  fetch('meerkat.watch/api/v0/enterprise/unsubscribe/address/<subscription_id>', {
                    method: "DELETE", 
                    headers: {
                      "Authorization": "ApiKey <your api token>",
                      "Content-Type": "application/json",
                    }
                  })
                `}
                  </code>
                </pre>
                <p className={styles.codeDescription}>Curl:</p>
                <pre className={styles.pre}>
                  <code className={`${styles.code} language-bash`}>
                    {`curl -X DELETE 'https://meerkat.watch/api/v0/enterprise/unsubscribe/address/<subscription_id>' \\
  -H 'Authorization: ApiKey <subscription_id>' \\
  -H 'Content-type: application/json' 
                    `}
                  </code>
                </pre>
                <p className={styles.codeDescription}>Response</p>
                <pre className={styles.pre}>
                  <code className={`${styles.code} language-bash`}>
                    204 - No Content
                  </code>
                </pre>
              </React.Fragment>
            </Section>
            <Section name="address-webhook">
              <React.Fragment>
                <h2 className={styles.tertiarytitle}>Address Webhook</h2>
                <p className={styles.description}>
                  A{' '}
                  <a
                    className={styles.scrollLink}
                    href="https://sendgrid.com/blog/whats-webhook/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    WebHook
                  </a>{' '}
                  is just a push notification from our server to yours. The
                  callback URL set up in{' '}
                  <ScrollLink className={styles.scrollLink} to="address-create">
                    Create Address Subscription
                  </ScrollLink>{' '}
                  pushes a notification to your server every time a transaction
                  occurs that involves the provided address.
                </p>
                <div className={styles.attributes}>
                  <h4 className={styles.attributeTitle}>Body Attributes</h4>
                  <ul className={styles.table}>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>timestamp</h5>
                      <p className={styles.attributeDescription}>
                        UTC time of event.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>currency</h5>
                      <p className={styles.attributeDescription}>
                        One of <code>BTC</code>, <code>LTC</code>,{' '}
                        <code>DOGE</code>, <code>ETH</code>,{' '}
                        <code>ERC20:{`<contract address>`}</code>. Please note{' '}
                        <code>ETH</code> is exclusive of ERC-20 addresses.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>type</h5>
                      <p className={styles.attributeDescription}>
                        One of <code>sent</code> or <code>received</code>.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>address</h5>
                      <p className={styles.attributeDescription}>
                        The subscribed address.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>amount</h5>
                      <p className={styles.attributeDescription}>
                        The amount of the transaction. This has been serialized
                        as a string to prevent javascript from using a double
                        when deserializing.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>txid</h5>
                      <p className={styles.attributeDescription}>
                        Transaction ID
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>event</h5>
                      <p className={styles.attributeDescription}>
                        One of <code>detected</code>, <code>mined</code>,{' '}
                        <code>confirmed</code>, <code>dropped</code>,{' '}
                        <code>uncled</code>, or <code>evicted</code>.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>isGas</h5>
                      <p className={styles.attributeDescription}>
                        ETH only: if the entirety of this event is a gas payment
                        for a contract call.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>blockHeight</h5>
                      <p className={styles.attributeDescription}>
                        Height of the block that the transaction was mined in
                        (only for <code>mined</code> or <code>confirmed</code>).
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>risk</h5>
                      <p className={styles.attributeDescription}>Not used.</p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>error</h5>
                      <p className={styles.attributeDescription}>
                        ETH/ERC20 only: an EVM exception thrown or where event
                        logs not produced.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>confirmedBalance</h5>
                      <p className={styles.attributeDescription}>
                        Confirmed balance for address. <code>null</code> unless
                        event is confirmed.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>inputs</h5>
                      <p className={styles.attributeDescription}>
                        Addresses of UTXOs used as inputs to this transactions.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>outputs</h5>
                      <p className={styles.attributeDescription}>
                        Addresses of UTXOs that were outputs from this
                        transaction.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>erc20Data</h5>
                      <p className={styles.attributeDescription}>ERC20 only</p>
                    </li>
                  </ul>
                </div>
              </React.Fragment>
              <React.Fragment>
                <p className={styles.codeDescription}>Example Webhook Body:</p>
                <pre className={styles.pre}>
                  <code className={`${styles.code} language-javascript`}>
                    {stripIndent`
                  {
                    "timestamp": "2019-01-2019T10:13:46Z",
                    "currency": "ERC20:0x4156D3342D5c385a87D264F90653733592000581",
                    "type": "received",
                    "address": "0xFb71DaA0c206Bb86eDb8AF3EC6a1E6caeADC1c5B",
                    "amount": "0.00000000",
                    "txid": "0xfb71daa0c206bb86edb8af3ec6a1e6caeadc1c5b6c7f77fc0b216b80391e8e73",
                    "event": "confirmed", 
                    "isGas": false,
                    "risk": 0.0,
                    "blockHeight": 7000000, 
                    "error": "EVM Exception", 
                    "confirmedBalance": "0.00000000", 
                    "inputs": ["0xF966785800AD69912378933d673f4d33f8E45a84"],
                    "outputs": ["0xFb71DaA0c206Bb86eDb8AF3EC6a1E6caeADC1c5B"], 
                    "erc20Data": {
                      "contractAddress": "0x4156D3342D5c385a87D264F90653733592000581",
                      "ticker": "SALT",
                      "decimals": 8
                    }
                  }
                `}
                  </code>
                </pre>
              </React.Fragment>
            </Section>
          </Element>
        </section>
        <section>
          <Element name="transaction">
            <Section>
              <React.Fragment>
                <h2 className={styles.subtitle}>Transaction Subscription</h2>
                <p className={styles.description}>
                  To create a transaction subscription, you need to provide a
                  currency type, callback URL, and txid. The callback URL will
                  be hit everytime the txid progresses through one of the
                  following states: <code>detected</code>, <code>mined</code>,{' '}
                  <code>confirmed</code>, <code>dropped</code>,{' '}
                  <code>uncled</code>, or <code>evicted</code>.
                </p>
              </React.Fragment>
            </Section>
            <Section name="transaction-create">
              <React.Fragment>
                <h3 className={styles.tertiarytitle}>
                  Create Transaction Subscription
                </h3>
                <div className={styles.attributes}>
                  <h4 className={styles.attributeTitle}>Request Body</h4>
                  <ul className={styles.table}>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>currency</h5>
                      <p className={styles.attributeDescription}>
                        The following are accepted currencies: <code>BTC</code>,{' '}
                        <code>LTC</code>, <code>DOGE</code>, <code>ETH</code>,{' '}
                        <code>ERC20: {`<contract address>`}</code>. Please note{' '}
                        <code>ETH</code> is exclusive of ERC-20 addresses.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>txid</h5>
                      <p className={styles.attributeDescription}>
                        Transaction ID
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>callback</h5>
                      <p className={styles.attributeDescription}>
                        A valid callback URL that accepts a <code>POST</code>{' '}
                        request. This is the URL that our server will send push
                        notifications to on transaction status changes.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className={styles.attributes}>
                  <h4 className={styles.attributeTitle}>Response</h4>
                  <ul className={styles.table}>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>subscription_id</h5>
                      <p className={styles.attributeDescription}>
                        A unique ID which can be used to delete the
                        subscription.
                      </p>
                    </li>
                  </ul>
                </div>
              </React.Fragment>
              <React.Fragment>
                <p className={styles.codeDescription}>Example Request:</p>
                <pre className={styles.pre}>
                  <code className={`${styles.code} language-javascript`}>
                    {stripIndent`
                      fetch('meerkat.watch/api/v0/enterprise/subscribe/transaction', {
                        method: "POST", 
                        headers: {
                          "Content-Type": "application/json",
                          "Authorization": "ApiKey <your api token>"
                        },
                        body: JSON.stringify({
                          "txid": "4a5e1e4baab89f3a32518a88c31bc87f618f76673e2cc77ab2127b7afdeda33b",
                          "currency": "BTC",
                          "callback": "https://example.com/txUpdate/1234"
                        })
                      })
                    `}
                  </code>
                </pre>
                <p className={styles.codeDescription}>Curl:</p>
                <pre className={styles.pre}>
                  <code className={`${styles.code} language-bash`}>
                    {`curl -XPOST 'https://meerkat.watch/api/v0/enterprise/subscribe/transaction' \\
  -H 'Authorization: ApiKey <your api token>' \\
  -H "Content-type: application/json" \\
  -d '{"txid":"4a5e1e4baab89f3a32518a88c31bc87f618f76673e2cc77ab2127b7afdeda33b","currency":"BTC","callback":"https://example.com/txUpdate/1234"}' 
                    `}
                  </code>
                </pre>
                <p className={styles.codeDescription}>Example Response:</p>
                <pre className={styles.pre}>
                  <code className={`${styles.code} language-javascript`}>
                    {stripIndent`"<subscription id>"`}
                  </code>
                </pre>
              </React.Fragment>
            </Section>
            <Section name="transaction-delete">
              <React.Fragment>
                <h2 className={styles.tertiarytitle}>
                  Delete Transaction Subscription
                </h2>
                <p className={styles.description}>
                  Use the <code>subscription_id</code> obtained from the{' '}
                  <ScrollLink
                    className={styles.scrollLink}
                    to="transaction-create"
                  >
                    Create transaction Subscription
                  </ScrollLink>{' '}
                  request to delete the subscription.
                </p>
              </React.Fragment>
              <React.Fragment>
                <p className={styles.codeDescription}>Example Request:</p>
                <pre className={styles.pre}>
                  <code className={`${styles.code} language-javascript`}>
                    {stripIndent`
                      fetch('meerkat.watch/api/v0/enterprise/unsubscribe/transaction/<subscription id>', {
                        method: "DELETE", 
                        headers: {
                          "Authorization": "ApiKey <your api token>",
                        }
                      })
                    `}
                  </code>
                </pre>
                <p className={styles.codeDescription}>Curl:</p>
                <pre className={styles.pre}>
                  <code className={`${styles.code} language-bash`}>
                    {stripIndent`curl -X DELETE 'https://meerkat.watch/api/v0/enterprise/unsubscribe/transaction/<subscription_id>' \\
  -H 'Authorization: ApiKey <your api token>' \\
  -H 'Content-type: application/json' 
                    `}
                  </code>
                </pre>
                <p className={styles.codeDescription}>Response</p>
                <pre className={styles.pre}>
                  <code className={`${styles.code} language-bash`}>
                    204 - No Content
                  </code>
                </pre>
              </React.Fragment>
            </Section>
            <Section name="transaction-webhook">
              <React.Fragment>
                <h2 className={styles.tertiarytitle}>Transaction Webhook</h2>
                <p className={styles.description}>
                  A{' '}
                  <a
                    className={styles.scrollLink}
                    href="https://sendgrid.com/blog/whats-webhook/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    WebHook
                  </a>{' '}
                  is just a push notification from our server to yours. The
                  callback URL set up in{' '}
                  <ScrollLink
                    className={styles.scrollLink}
                    to="transaction-create"
                  >
                    Create Transaction Subscription
                  </ScrollLink>{' '}
                  pushes a notification to your server every time the status of
                  the transaction changes.
                </p>
                <div className={styles.attributes}>
                  <h4 className={styles.attributeTitle}>Body Attributes</h4>
                  <ul className={styles.table}>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>timestamp</h5>
                      <p className={styles.attributeDescription}>
                        UTC time of event.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>currency</h5>
                      <p className={styles.attributeDescription}>
                        One of <code>BTC</code>, <code>LTC</code>,{' '}
                        <code>DOGE</code>, <code>ETH</code>,{' '}
                        <code>ERC20:{`<contract address>`}</code>. Please note{' '}
                        <code>ETH</code> is exclusive of ERC-20 addresses.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>txid</h5>
                      <p className={styles.attributeDescription}>
                        Transaction ID
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>event</h5>
                      <p className={styles.attributeDescription}>
                        One of <code>detected</code>, <code>mined</code>,{' '}
                        <code>confirmed</code>, <code>dropped</code>,{' '}
                        <code>uncled</code>, or <code>evicted</code>.
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>blockHeight</h5>
                      <p className={styles.attributeDescription}>
                        Height of the block that the transaction was mined in
                        (only for <code>mined</code> or <code>confirmed</code>).
                      </p>
                    </li>
                    <li className={styles.tableRow}>
                      <h5 className={styles.attribute}>error</h5>
                      <p className={styles.attributeDescription}>
                        ETH/ERC20 only: an EVM exception thrown or where event
                        logs not produced.
                      </p>
                    </li>
                  </ul>
                </div>
              </React.Fragment>
              <React.Fragment>
                <p className={styles.codeDescription}>Example Webhook Body:</p>
                <pre className={styles.pre}>
                  <code className={`${styles.code} language-javascript`}>
                    {stripIndent`
                      {
                        "timestamp": "2009-01-03T18:15:05Z",
                        "currency": "BTC",
                        "txid": "4a5e1e4baab89f3a32518a88c31bc87f618f76673e2cc77ab2127b7afdeda33b",
                        "event": "mined",
                        "blockHeight": 0,
                        "error": null
                      }
                    `}
                  </code>
                </pre>
              </React.Fragment>
            </Section>
          </Element>
        </section>
      </main>
    )
  }
}
